.sandbox-outer-layer {
  background-color: rgb(11, 11, 11);
  padding: 20px;
  margin: 10px;
  width: fit-content;
  cursor: pointer;
}

.sandbox-middle-layer {
  background-color: rgb(34, 32, 32);
  padding: 20px;
}

.sandbox-inner-layer {
  background-color: rgba(205, 207, 196, 0.977);
  color: black;
  font-weight: bold;
  padding: 10px;
  width: 100px;
  height: 100px;
}

.centerise {
  display: flex;
  justify-content: center;
  align-items: center;
}
