:root {
  --primary: #1d3461;
  --secondary: #1f487e;
}

body {
  margin: 0px;
  padding: 0px;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  height: 100%;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  height: 40px;
  padding: 10px;
  align-items: center;
}

.header-item {
  color: black;
  font-weight: 600;
  font-size: 16px;
  padding: 10px;
}

.header-item-left {
  display: flex;
  justify-content: center;
  align-items: center;
}
.outlet-container {
}
.implementation-button {
  position: fixed;
  bottom: 60px;
  right: 60px;
  background-color: var(--primary);
  color: white;
  height: 50px;
  border: none;
  border-radius: 50px;
  padding: 10px;
  font-weight: bold;
}
.outlet-container {
  display: flex;
  justify-content: center;
}
.outlet-style {
  width: 100%;
}
