.main-section {
  display: flex;
  justify-content: center;
}
.article-section {
  max-width: 700px;
}

.home-container {
  width: 100%;
  height: 100%;
}
.home-text {
  text-align: center;
  font-size: 20px;
  margin-top: 20px;
}

.home-sandboxes {
  flex-wrap: wrap;
  display: flex;
  padding: 20px;
}
