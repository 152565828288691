.banner {
  background-color: black;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.tile {
  border: 3px solid white;
  margin: 5px;
  height: 20px;
  width: 100%;
  border-radius: 5px;
  text-align: center;
}
.tileRow {
  display: flex;
  width: 100%;
}
